<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">Home Page </v-col>
    </v-row>
  </v-container>
</template>

<script>
// service
import { onMounted } from "@vue/composition-api";
export default {
  name: "Dashboard",
  setup(props, { root }) {
    const { $router } = root;
    onMounted(() => {
      // auto push to profile/settings
      $router.push(`/profile/settings`);
    });
  }
};
</script>